//GLOBAL
@import 'globals.scss';
@import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';

body {
  font-family: $mainFont;
}

//NAME
#first {
  font-size: 250%;
}

#dot {
  line-height: 20pt;
  font-size: 600%;
}

#last {
  font-size: 150%;
}

#pageType {
  font-size: 200%;
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
  margin: 35px 0px 25px;
  text-align: center;
}

#topname {
  height: 76px;
  text-align: center;
}

//BUTTONS
#top-buttons .top-button {
  display: inline-block;
}

#top-buttons {
  text-align: center;
}

#highlightedlink {
  height: 10px;
  color: lightgrey;
  width: 100%;
  top: -22px;
  text-align: center;
  position: absolute;
}

#button-container {
  position: relative;
}

#top-buttons {
  a {
    color: lightgrey;
    margin-left: 2px;
    margin-right: 2px;
  }

  a:hover {
    color: darkorange;
  }
}

// POST
$post-padding: 15px;

.post-date {
  font-weight: 700;
  font-style: italic;
  grid-area: date;
  padding-left: 15px;
}

.post-content {
  grid-area: content;
  border-left: 1px solid black;
  border-right: 1px solid black;
  padding: 0px $post-padding;
}

.post-title {
  grid-area: title;
  font-style: italic;
  font-size: 200%;
}

.post-tags {
  text-align: right;
  color: #d3d3d3;
  padding-right: $post-padding;
}

.post {
  display: grid;
  grid-template-areas: "date title tags" "content content content";
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  margin-bottom: 50px;
}

.post a {
  color: darkorange;
  text-decoration: none;
}

// CONTENT
figure {
  width: 100%;
  text-align: center;
}

// TAG FILTERS
#tagFilters {
  text-align: center;
  margin-bottom: 15px;
  margin-top: -20px;
}

.tagFilter:hover {
  color: darkorange;
}

.tagFilter.active {
  color: grey;
}

.tagFilter:hover {
  color: darkorange;
}

.tagFilter.active:hover {
  color: darkgoldenrod;
}

.tagFilter {
  color: lightgrey;
  background-color: white;
  border: none;
  padding: 0pt 3pt;
}

// CV
#cv-header {
  text-align: center;
  font-size: 150%;
  border-bottom: 1px solid #d3d3d3;
}

.cvitem {
  display: grid;
  grid-template-areas:
    "icon where"
    "icon when"
    "icon role"
    "icon description"
    "icon diploma";
  grid-template-columns: auto 1fr;
  margin: 10px 0px;

  .cv-diploma {
    font-style: italic;
  }

  .cv-where {
    grid-area: where;
    font-size: 1.25rem;
    font-weight: 300;
  }

  .cv-when {
    font-style: italic !important;
    grid-area: when;
  }

  .cv-role {
    grid-area: role;
  }

  .cv-icon {
    grid-area: icon;
    margin: 0px 40px 0px 20px;
    border-right: 1px solid lightgray;
    padding-right: 30px;
    width: 120px;
    height: 90px;

    img {
      object-fit: scale-down;
      max-width: 90px;
      max-height: 90px;

      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

// CONTACT

#work-address .text-right {
  text-align: right;
}

#work-address {
  margin-top: 10px;
}

.contact-header {
  font-size: 140%;
  text-align: center;
  border-bottom: 1px solid #a9a9a9;
}

.contact-block {
  margin-bottom: 20px;
}

#platforms {
  text-align: center;
  margin-top: 10px;

  a {
    margin: 0px 5px;
  }
}
