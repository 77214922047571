#topname {
  //font-family: $mainFont;
  margin-top: 25px;
  padding-bottom: 9px;
  font-size: 80%;
  color: lighten(black, 90%);
  transition: font-size 0.15s ease, color 0.15s;
}

#topname.active {
  font-size: 100%;
  color: black;
  transition: font-size 0.30s ease, color 0.15s;
}

#topname #first {
  font-size: 250%
}

#topname #dot {
  line-height: 20pt;
  font-size: 600%;
}

#topname #last {
  font-size: 150%;
}
